<template>
  <base-section id="menu" space="0">
    <v-container id="review-container">
      <p class="text-center text-h5 font-weight-bold pt-10">
        Resumen de la compra:
      </p>
      <v-img v-if="getQrUrl !== null" justify="center" :src="getQrUrl" />
      <p class="text-center text-h6 font-weight-regular pt-10">
        Cantidad disponible: <strong>{{ availableQuantity }}</strong>
      </p>
      <p class="text-center text-h6 font-weight-regular pt-10">
        Cantidad consumida: <strong>{{ consumedQuantity }}</strong>
      </p>
    </v-container>
  </base-section>
</template>

<script>
import qrCode from "@/mixins/qrCode";
import { getOrderStatus } from "@/plugins/axios";
export default {
  name: "OrderStatusSection",
  mixins: [qrCode],
  data: () => {
    return {
      qrUrl: null,
      availableQuantity: 0,
      consumedQuantity: 0,
    };
  },
  mounted() {
    this.getOrderStatus();
  },
  methods: {
    async getOrderStatus() {
      const payload = {
        shopCode: this.$route.query.commerceId,
        code: this.$route.query.c,
        pup: this.$route.query.pup,
      };

      try {
        const result = await getOrderStatus(payload);
        if (result.data.responseCode !== 0) {
          this.showError(result.data.responseMessage);
          return;
        }

        const orderStatus = result.data.orderStatus;

        this.availableQuantity = orderStatus.availableQuantity;
        this.consumedQuantity = orderStatus.consumedQuantity;

        this.generateQrCode(orderStatus.qrFilePath, (url) => {
          this.qrUrl = url;
        });
      } catch (ex) {
        this.showError();
      } finally {
      }
    },
    showError(responseMessage) {
      const msg =
        responseMessage ||
        "Tuvimos un problema al recuperar el estado de la orden. Reintentá más tarde por favor";

      this.$store.commit("snackbar/OPEN_SNACKBAR", {
        msg: msg,
        success: false,
      });
    },
  },
  computed: {
    getQrUrl() {
      return this.qrUrl;
    },
  },
};
</script>
<style scoped></style>
